import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchInspectionPoints, selectAllInspectionPoints } from '../inspectionPoints/inspectionPointsSlice';
import { createInspection } from './inspectionsSlice';
import {  Box, Button, TextField, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import RadioButtons from './RadioButtons';
import NotesField from './NotesField';
import SignaturePad from 'react-signature-canvas';

const InspectionForm = () => {
    // geting the property id from the location state
    const location = useLocation();
    const { from } = location.state
    const propertyId = from;
    // for navigation after submitting the form
    const navigate = useNavigate();
    // getting the current user from the AuthContext
    const { currentUser } = useContext(AuthContext);
    // state variables
    const [points, setPoints] = useState([]);
    const [rooms, setRooms] = useState(null);
    const [bedroomCount, setBedroomCount] = useState(0);
    const [bathroomCount, setBathroomCount] = useState(0);
    const [formGenerated, setFormGenerated] = useState(false);
    const [inspectionData, setInspectionData] = useState({});
    const [radioValues, setRadioValues] = useState({});
    const inspectionPoints = useSelector(selectAllInspectionPoints);
    const dispatch = useDispatch();

    let sigPad = useRef({});

    // signature functions
    const clearSignatrue = () => {
        sigPad.current.clear();
    }

    let dataURL = null;

    const saveSignature = () => {
        dataURL = sigPad.current.toDataURL();
        console.log('signature saved')
        
    }

    // fetching the inspection points from the database
    useEffect(() => {
        dispatch(fetchInspectionPoints());
    }, [dispatch]);
    
    // creating the inspection points array

    let filteredInspectionPoints = inspectionPoints;

    if (rooms) {
        filteredInspectionPoints = inspectionPoints.filter(point => {
            if (point.inspectionArea.includes("Bathroom")) {
                const bathNumber = parseInt(point.inspectionArea.split(" ")[1]);
                return bathNumber <= rooms.bathrooms;
            } else if (point.inspectionArea.includes("Bedroom")) {
                const bedNumber = parseInt(point.inspectionArea.split(" ")[1]);
                return bedNumber <= rooms.bedrooms;
            }
            return true;
        });
    }

    // grouping the inspection points by inspection area
    const groupByInspectionArea = (inspectionPoints) => {
        return inspectionPoints.reduce((groups, point) => {
            const key = point.inspectionArea;
            if (!groups[key]) {
            groups[key] = [];
            }
            groups[key].push(point);
            return groups;
        }, {});
    };

    // creating the inspection points array
    const tempPointsRef = useRef([]);

    // onChange handler for the inspection points
    const handleBlur = (index, key, value, point, inspectionArea) => {
        const tempPoints = tempPointsRef.current;
        const existingPointIndex = tempPoints.findIndex(p => p.point === point && p.inspectionArea === inspectionArea);
        
        const currentRadioValue = radioValues[`${point}-${inspectionArea}`] || "";
        
        if (existingPointIndex !== -1) {
            tempPoints[existingPointIndex] = {
                ...tempPoints[existingPointIndex],
                [key]: value,
                score: key === 'score' ? value : currentRadioValue  // This line makes sure the radio value is not overwritten unless it's being explicitly updated
            };
        } else {
            tempPoints.push({ 
                [key]: value, 
                point, 
                inspectionArea,
                score: key === 'score' ? value : currentRadioValue  // This line does the same for new points
            });
        }
    
        // Update the radio value in the state
        if (key === 'score') {
            setRadioValues(prev => ({
                ...prev,
                [`${point}-${inspectionArea}`]: value
            }));
        }
    };
    
    
    
    // Pass All handler
    const handlePassAll = (inspectionArea) => {
        const tempPoints = tempPointsRef.current;
        
        let newRadioValues = { ...radioValues };
    
        groupedPoints[inspectionArea].forEach(point => {
            const existingPointIndex = tempPoints.findIndex(p => p.point === point.inspectionPoint && p.inspectionArea === inspectionArea);
            
            const updatedPoint = {
                point: point.inspectionPoint,
                inspectionArea: inspectionArea,
                score: "Pass"
            };
    
            if (existingPointIndex !== -1) {
                tempPoints[existingPointIndex] = updatedPoint;
            } else {
                tempPoints.push(updatedPoint);
            }
    
            newRadioValues[`${point.inspectionPoint}-${inspectionArea}`] = "Pass";
        });
    
        tempPointsRef.current = [...tempPoints];
        setRadioValues(newRadioValues);
    };
    

    // onSubmit handlers
    const handleSubmit = async (e) => {
        e.preventDefault();
        const tempPoints = tempPointsRef.current;
        console.log(tempPoints);
        console.log(currentUser);
        const actionResult = await dispatch(createInspection({ property: propertyId, inspector: currentUser.id, pointsOfInspection: tempPoints, signature: dataURL }));
        console.log(actionResult)
        if (createInspection.fulfilled.match(actionResult)) {
            navigate(`/`);
        }
    };

    const handleFormGeneration = (e) => {
        e.preventDefault();
        if (bedroomCount !== null && bathroomCount !== null) {
            setRooms({ bedrooms: bedroomCount, bathrooms: bathroomCount });
            setFormGenerated(true);
        }
    };

    const groupedPoints = groupByInspectionArea(filteredInspectionPoints);

    if (!formGenerated) {
        return (
            <Box component='form' onSubmit={handleFormGeneration}>
                <Stack direction='column' spacing={2}>
                    <TextField
                        label="Number of Bedrooms"
                        type="number"
                        onChange={(e) => setBedroomCount(parseInt(e.target.value))}
                    />
                    <TextField
                        label="Number of Bathrooms"
                        type="number"
                        onChange={(e) => setBathroomCount(parseInt(e.target.value))}
                    />
                    <Button variant="contained" type='submit' color='primary'>Generate Form</Button>
                </Stack>

            </Box>
        );
        } else {
            const groupedPoints = groupByInspectionArea(filteredInspectionPoints);
            return (
                <form onSubmit={handleSubmit}>
                    {Object.entries(groupedPoints).map(([area, points], index) => (
                        <div key={index}>
                            <h2>{area}</h2>
                            {/* "Pass All" button for this inspection area */}
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={() => handlePassAll(area)}
                            >
                                Pass All for {area}
                            </Button>
                            {points.map((point, index) => (
                                <div key={index}>
                                    <Stack direction='row' spacing={2}>
                                        <RadioButtons 
                                            index={index} 
                                            handleBlur={handleBlur} 
                                            point={point} 
                                            inspectionArea={area} 
                                            radioValue={radioValues[`${point.inspectionPoint}-${area}`] || ''}/>
                                        <NotesField index={index} handleBlur={handleBlur} point={point} inspectionArea={area}/>
                                    </Stack>
                                </div>
                            ))}
                        </div>
                    ))}
                    <Box sx={{ border: '1px solid black' }}>
                        <Button onClick={clearSignatrue}>Clear Signature</Button>
                        <SignaturePad
                            ref={sigPad}
                            canvasProps={{
                                width: 1200,
                                height: 200,
                            }}
                        />
                        <Button variant="contained" onClick={saveSignature}>Save Signature</Button>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 3, width: '100%', justifyContent: 'center'}}>
                        <Button variant="contained" color="primary" type="submit" sx={{ width: '30%'}}>Submit Inspection</Button>
                    </Box>
                </form>
            );
        }
    };

export default InspectionForm;