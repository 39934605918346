import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInspectionPoints, postInspectionPoint, selectAllInspectionPoints } from './inspectionPointsSlice';
import _ from 'lodash';

const AddPoint = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [inspectionPoint, setInspectionPoint] = useState('');
  const [inspectionArea, setInspectionArea] = useState('');

  useEffect(() => {
    dispatch(fetchInspectionPoints());
  }, [dispatch]);

  const inspectionPoints = useSelector(selectAllInspectionPoints);

  const inspectionAreas = _.uniq(inspectionPoints.map(point => point.inspectionArea));

    
  const handleSubmit = () => {
    dispatch(postInspectionPoint({
      inspectionPoint,
      inspectionArea
    }));
    handleClose();
  };

  return (
    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', m: 2, width: 400 }}>
      <Typography variant="h6" gutterBottom component="div">
        Add Inspection Point
      </Typography>
      <TextField
        autoFocus
        label="Inspection Point"
        value={inspectionPoint}
        onChange={(e) => setInspectionPoint(e.target.value)}
        margin="normal"
      />
      <FormControl margin="normal">
        <InputLabel>Inspection Area</InputLabel>
        <Select
          value={inspectionArea}
          onChange={(e) => setInspectionArea(e.target.value)}
        >
          {inspectionAreas.map((area) => (
            <MenuItem key={area} value={area}>
              {area}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="secondary" onClick={handleSubmit}>
        Add Inspection Point
      </Button>
    </Box>
  );
};

export default AddPoint;
