import { TextField } from '@mui/material';

const NotesField = ({ index, handleBlur, point, inspectionArea }) => {

    return (
        <TextField
            label="Notes"
            fullWidth
            rows={4}
            variant="outlined"
            onBlur={(e) => handleBlur(index, 'notes', e.target.value, point.inspectionPoint, inspectionArea)}
        />
    );
};

export default NotesField;