import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchContacts } from './contactsSlice';
import StyledDataGrid from '../../components/StyledDataGrid';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';

const ContactsList = () => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contacts);
  const status = useSelector(state => state.contacts.status);
  const deviceType = useSelector((state) => state.appSettings.device);

  useEffect(() => {
    dispatch(fetchContacts());
  }, [dispatch]);

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (!contacts || !Array.isArray(contacts)) {
    return null;
  }

  const mobileColumns = [
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'phone', headerName: 'Phone', flex: 2 },
    { field: 'fullInfo', headerName: 'Info', flex: 1, sortable: false, renderCell: (params) =>  <Link to={`/contacts/${params.row.id}`}> <InfoIcon /> </Link>  },
  ]

  const mobileRows = contacts.map((contact) => ({
    id: contact._id,
    name: contact.name,
    phone: contact.phoneNumber,
    contact,
  }));

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'role', headerName: 'Contact Type', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'properties', headerName: 'Properties', flex: 1 },
    { field: 'fullInfo', headerName: 'Full Info', flex: 1, sortable: false, renderCell: (params) =>  <Link to={`/contacts/${params.row.id}`}> <InfoIcon /> </Link>  },
  ]

  const rows = contacts.map((contact) => ({
    id: contact._id,
    name: contact.name,
    phone: contact.phoneNumber,
    role: contact.role,
    email: contact.email,
    properties: contact.properties ? contact.properties.length : 0,
    contact,
  }));

  if (deviceType === 'mobile') {
    return (
    <StyledDataGrid
      rows={mobileRows}
      columns={mobileColumns}
      />
    )
  } else {
    return (
      <StyledDataGrid 
        rows={rows}
        columns={columns}
      />
    )
  }
};

export default ContactsList;
