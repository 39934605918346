import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postNote } from './notesSlice';  // You need to add the `postNote` async thunk to your note slice
import { AuthContext } from '../../context/AuthContext';
import { Box, TextField, Input, Divider, FormControlLabel, Checkbox, Stack, Button } from '@mui/material';
import CustomFormControlLabel from '../../theme/customComponents/CustomFormControlLabel';


function CreateNote() {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const [content, setContent] = useState('');
  const [tag, setTag] = useState('');
  const [isPublic, setIsPublic] = useState(true);

//   console.log(currentUser.username)
//   const author = currentUser.username

//   const user = useSelector(state => state.user.user);

  const handleSubmit = e => {
    e.preventDefault();
    if (content) {
      const author = currentUser.username
      dispatch(postNote({ 
        author,
        content,
        tag,
        isPublic
      }));
      setContent('');
      setTag('');
      setIsPublic(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
        <Box sx={{ width: "100%", borderRadius: 4, p: 2, backgroundColor: '#1E2959'}}>
            <Stack direction="column" spacing={2}>
                <TextField 
                    color="blanco"
                    sx={{ backgroundColor: 'white'}}
                    value={content}
                    multiline
                    rows={3}
                    onChange={e => setContent(e.target.value)}
                    placeholder="Leave a message?"
                    />
                    <Input 
                        type='text'
                        value={tag}
                        onChange={e => setTag(e.target.value)}
                        placeholder="Tag a user"
                        sx={{  backgroundColor: 'white'}}
                        />
                <Stack direction="row" spacing={2}>
                <CustomFormControlLabel
                    label="Public"
                    control={<Checkbox checked={isPublic} 
                    onChange={e => setIsPublic(e.target.checked)} 
                    sx={{ '&.MuiFormControlLabel-label': {color: 'white'} }} />}
                    />
                <Button type="submit" fullWidth sx={{ backgroundColor: 'white' }}>Post</Button>
                </Stack>
            </Stack>
        </Box>
        <Divider sx={{ backgroundColor: 'white', mx: 5 }} />
    </form>
  );
}

export default CreateNote;
