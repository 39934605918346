import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const token = localStorage.getItem('token');

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createInspection = createAsyncThunk(
  'inspections/createInspection',
  async (inspectionData, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'post',
        url: `${API_BASE_URL}/inspections`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: inspectionData,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInspection = createAsyncThunk('inspections/fetchInspection', async (inspectionId) => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${API_BASE_URL}/inspections/${inspectionId}`, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});


const inspectionsSlice = createSlice({
  name: 'inspections',
  initialState: { status: 'idle', error: null, inspection: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createInspection.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createInspection.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createInspection.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchInspection.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInspection.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.inspection = action.payload;
      });
  },
});

export default inspectionsSlice.reducer;