import React, { useEffect } from "react";
// import { appSetting } from "../../redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setDevice } from "../redux/appSettingsSlice";
// import { chain, set } from "lodash";
// import useNotification from "../../@tracs/hooks/useNotification";
// import { useUpdateEffect } from "../../@tracs/hooks";

// const useStyles = makeStyles((theme) => ({
//   rootLayout: {
//     background: theme.palette.background.default,
//     flexGrow: 1,
//     margin: 0,
//     padding: 0,
//     position: "relative", // set the position to relative
//     width: "calc(100vw - 284px)",
//     height: "100%",
//   },
//   footer: {
//     position: "absolute", // set the position to absolute
//     bottom: 0, // stick the footer to the bottom of the AppWrapper
//     height: 60, // set a fixed height for the footer
//     backgroundColor: "transparent",
//     width: "100%",
//     zIndex: 10,
//   },
//   main: {
//     background: theme.palette.background.default,
//     flexGrow: 1,
//     height: "100%", // set a minimum height to allow the sticky footer to work
//   },
// }));

function AppWrapper(props) {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.between("md"));
  // const [msg, sendNotification] = useNotification();
  // const notificationSuccess = useSelector(
  //   (state) => state.App.notificationSuccess
  // );

  // React.useEffect(() => {
  //   // adds global toast
  //   window.TNotification = sendNotification;
  // }, []);

  // useUpdateEffect(() => {
  //   sendNotification({
  //     msg: notificationSuccess.msg,
  //     variant: notificationSuccess.variant,
  //   });
  // }, [notificationSuccess]);

  // use effect that deterines what device the user is on and puts it into a single string
  useEffect(() => {
    if (mobile) {
      dispatch(
        setDevice('mobile')
      );
    } else if (desktop) {
      dispatch(
        setDevice('desktop')
      );
    } else if (tablet) {
      dispatch(
        setDevice('tablet')
      );
    }
  }, [mobile, desktop, tablet, dispatch]);

  return props.children;
}

export default AppWrapper;