import { Button } from '@mui/material'

const AppButton = ({ onClick, title }) => {
  return (
    <Button 
        variant="contained"
        color="complement"
        sx={{ color: 'white'}}
        onClick={onClick}>
        {title}
    </Button>
  )
}

export default AppButton