import { Box } from '@mui/material'
import Logo from './images/Logo.svg'

const LogoComponent = () => {
    return (
        <Box >
            <img style={{ width: "100%" }} src={Logo} alt='logo' />
        </Box>
    )
}

export default LogoComponent