import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const AuthContext = createContext();

export const isTokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  return decodedToken.exp * 1000 < Date.now();
};



export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authInitialized, setAuthInitialized] = useState(false);


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      if (isTokenExpired(token)) {
        logout();
      } else {
        const decodedToken = jwtDecode(token);
        setCurrentUser(decodedToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    }
    setIsLoading(false);
    setAuthInitialized(true);
  }, []);

  const login = async (username, password) => {
    const response = await axios.post(`${API_BASE_URL}/users/login`, { username, password });
    const token = response.data.token;

    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // Update the currentUser state with the logged-in user information
    const decodedToken = jwtDecode(token);
    setCurrentUser(decodedToken);

    return token; // you can still return the token if needed elsewhere
  };

  const logout = async () => {
    setCurrentUser(null);
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, login, logout, isLoading }}>
      {authInitialized && children}
    </AuthContext.Provider>
  );
};
