import { useState } from "react";
import Appbar from "./appbar";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import MainDrawer, { drawerWidth } from "./drawer";
import { appBarHeight } from "./appbar";
import AppFooter from './footer';
import { useSelector } from 'react-redux';


// Layout Component to render the sidebar and appbar

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const deviceType = useSelector((state) => state.appSettings.device);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    

    return (
        <div className="App" style={{ overscrollBehavior: 'contain' }}>
            {deviceType !== 'mobile' &&
            <MainDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
            <Appbar handleDrawerToggle={handleDrawerToggle} />
            <Box
                sx={{ ml: [0, 0, drawerWidth], mt: appBarHeight, bgcolor: "#F0F3FF", p: [1, 5], height: "90vh", width: ['100%', '100%', `calc(100% - ${drawerWidth})`], overflow: 'auto' }}
                >
                <Outlet />
            </Box>
            <Box sx={{ bottom: 0, mt: 10 }}>
                {deviceType === 'mobile' && <AppFooter />}
            </Box>
        </div>
    );
};

export default Layout;
