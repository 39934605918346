// PropertyStatus.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProperties } from '../properties/propertiesSlice';
import { Box, Grid, Typography } from '@mui/material';

const PropertyStatus = () => {
    const dispatch = useDispatch();
    const properties = useSelector(state => state.properties);
    const [dueForInspection, setDueForInspection] = useState(0);

    useEffect(() => {
        dispatch(fetchProperties());
    }, [dispatch]);

    useEffect(() => {
        let dueCount = 0;
        for (let property of properties) {
            const inspections = property.inspections;
            if (!inspections.length) {
                dueCount++;
            } else {
                const lastInspection = inspections[inspections.length - 1];
                const lastInspectionDate = new Date(lastInspection.date);
                const oneYearAgo = new Date();
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                if (lastInspectionDate < oneYearAgo) {
                    dueCount++;
                }
            }
        }
        setDueForInspection(dueCount);
    }, [properties]);

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        p: {xs: 2, sm: 2, md:3, lg: 4, xl: 5},
        backgroundColor: '#1E2959'
    }

    const textStyle = {
        color: 'white'
    }

    return (
        <Box>
            <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Grid item xs={12} md={6} xl={6}>
                    <Box sx={boxStyle}>
                        <Typography variant="h4" align='center' sx={textStyle}>Total Properties:</Typography>
                        <Typography variant="h2" sx={textStyle}>{properties.length}</Typography>
                    </Box>
                    
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <Box sx={boxStyle}>
                        <Typography variant="h4" align='center' sx={textStyle} >Due For Inspection:</Typography>
                        <Typography variant="h2" sx={textStyle}>{dueForInspection}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PropertyStatus;
