// MUI icon imports
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const menuItems = [
    {
        name: 'Dashboard',
        path: '/',
        icon: <DashboardIcon />
    },
    {
        name: 'Property List',
        path: '/properties',
        icon: <HomeIcon />
    },
    {
        name: 'Contact List',
        path: '/contacts',
        icon: <PeopleIcon />
    },
    {
        name: 'Inspection Points',
        path: '/points-of-inspection',
        icon: <AssignmentTurnedInIcon />
    },
    {
        name: 'Users',
        path: '/users',
        icon: <PeopleIcon />
    },
]

export default menuItems;
