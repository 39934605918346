import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchProperties = createAsyncThunk('properties/fetchProperties', async () => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${API_BASE_URL}/properties`, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const addProperty = createAsyncThunk('properties/addProperty', async (newProperty) => {
  const token = localStorage.getItem('token');
  const response = await axios.post(`${API_BASE_URL}/properties`, newProperty, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

const propertiesSlice = createSlice({
  name: 'properties',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProperties.fulfilled, (state, action) => {
      return [...action.payload];
    })
    .addCase(addProperty.fulfilled, (state, action) => {
      return [...state, action.payload.property];
    });
  },
});

export default propertiesSlice.reducer;