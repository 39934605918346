import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import LogoComponent from '../../../assets/Logo'
import { AuthContext } from '../../../context/AuthContext'

const DrawerHeader = () => {
    const user = useContext(AuthContext);
    let username;
    if (user.currentUser) {
        username = user.currentUser.username
    } else {
        username = 'User'
    }
    
    // user.currentUser.username ? username = user.currentUser.username : username = 'User'
    console.log(user)
    return (
        <Box sx= {{ mt: 3, px: 2 }}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <LogoComponent />
                <Chip
                    label={process.env.REACT_APP_VERSION}
            
                    sx={{
                        backgroundColor: 'white',
                        height: '16px',
                        '& .MuiChip-label': { fontSize: '.625rem', py: 1.25 }
                    }}
                />
            </Stack>
            <Typography variant='h4' sx={{ my: 2.5, color: "white" }}>
                Welcome, {username}!
            </Typography>
            <Divider sx={{ backgroundColor: 'white' }} />
        </Box>
    )
}

export default DrawerHeader