// Project imports
import { CssBaseline } from '@mui/material';
import Dashboard from './features/dashboard';
import ContactInfo from './features/contacts/ContactInfo';
import Inspection from './features/inspections/Inspection';
import { AuthContext } from './context/AuthContext'
// import PropertyInfo from './pages/PropertyInfo';
import InspectionPoints from './features/inspectionPoints/InspectionPoints';
import Login from './auth/Login';
import Layout from './layout/Layout';
import InspectionForm from './features/inspections/InspectionForm';
import ContactsList from './features/contacts/ContactsList';

// React Router Dom imports
import { Routes, Route } from 'react-router-dom';
import PropertiesList from './features/properties/PropertiesList';
import AddProperty from './features/properties/AddProperty';
import UsersList from './features/users/UsersList';
import NewVisitor from './auth/NewVisitor';
import HomeRoute from './components/HomeRoute';
import { useContext } from 'react';

document.title = 'Rimersburg Inspections';

function App() {
  const { currentUser } = useContext(AuthContext);
  return (
    <div >
      <CssBaseline />
        <Routes>
        <Route path="/" element={currentUser ? <Layout /> : <NewVisitor />} >
            <Route index element={<Dashboard />} />
            <Route path="login" element={<Login />} />
            <Route path="properties" element={<PropertiesList />} />
            <Route path="properties/new" element={<AddProperty />} />
            <Route path="contacts" element={<ContactsList />} />
            <Route path="contacts/:id" element={<ContactInfo />} />
            <Route path="inspections/:id" element={<Inspection />} />
            {/* <Route path="properties/:id" element={<PropertyInfo />} /> */}
            <Route path="points-of-inspection" element={<InspectionPoints />} />
            <Route path="inspection-form" element={<InspectionForm />} />
            <Route path="users" element={<UsersList />} />
          </Route>
        </Routes>
    </div>
  );
}

export default App;