import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

const CustomFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({ theme, variant }) => ({
    ...({
      '& .MuiTypography-body1': {
        color: theme.palette.common.white,
      },
    }),
  }),
);

export default CustomFormControlLabel;
