import { Box, Grid, Typography } from '@mui/material';
import BasicCal from './BasicCal';
import Agenda from './Agenda';

const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
    border: '1px solid black',
    mt: [5, 5, 10],
    mx: [0, 1, 5],
    p: 4,
    borderRadius: 3,
    backgroundColor: '#f5f5f5'
}

const Schedule = () => {
    return (
        <Box sx={boxStyle} spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" align='center'>Inspection Schedule</Typography>
                    <Typography variant="h5" align='center'>Coming Soon!</Typography>
                </Grid>
                <Grid item xs={12} >
                    <BasicCal />
                </Grid>
                <Grid item xs={12} >
                    <Agenda />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Schedule