import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotes } from './notesSlice';
import Notes from './Note';
import { Box } from '@mui/material';

function NoteList() {
  const notes = useSelector(state => state.notes.notes);
  const status = useSelector(state => state.notes.status);
  const dispatch = useDispatch();

  // Sort notes by date
  const sortedNotes = [...notes].sort((a, b) => new Date(b.postDate) - new Date(a.postDate));

  // useEffect(() => {
  //   dispatch(fetchNotes());
  // }, [dispatch]);

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'succeeded') {
    return (
      <Box sx={{ maxHeight: '50vh', overflowY: 'scroll'}}>
        {sortedNotes.map(note => (
          <Notes key={note._id} note={note} />
        ))}
      </Box>
    );
  }
}


export default NoteList;
