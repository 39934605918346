import React, { useContext, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import backgroundVideo from "../assets/videos/backgroundVideo.mp4";
import LogoComponent from "../assets/Logo";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

// Css styling for the login

const NewVisitor = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { login, setCurrentUser } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loginContainer = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: ['95vw', '60vw', '25vw'],
    borderRadius: "10px",
    backgroundColor: "rgb(30, 41, 89, .85)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    py: 4,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "40vw",
    },
  };
  const textFieldStyle = {
    display: "flex",
    justifyItems: "center",
    width: "80%",
    backgroundColor: "white",
    borderRadius: "5px",
    my: 2,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = await login(username, password);

      // Store the token in local storage
      localStorage.setItem("token", token);

      // Decode the token to get the user object
      const decodedToken = jwtDecode(token);
      setCurrentUser(decodedToken);
      navigate("/");
    } catch (error) {
      setUsername("");
      setPassword("");
      setError("Invalid username or password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        border: "1px solid black",
        position: "relative",
      }}
    >
      <video
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        autoPlay
        loop
        muted
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <form onSubmit={handleSubmit}>
        <Box sx={loginContainer}>
          <LogoComponent />
          <Typography variant="h4" color="white" sx={{ mt: 4 }}>
            Username :
          </Typography>
          <TextField
            disabled={isLoading}
            autoFocus
            value={username}
            inputProps={{
              style: { fontSize: "1.5rem", padding: 5, textAlign: "center" },
            }}
            variant="filled"
            sx={textFieldStyle}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Typography variant="h4" color="white">
            Password :
          </Typography>
          <TextField
            disabled={isLoading}
            type="password"
            value={password}
            variant="filled"
            inputProps={{
              style: { fontSize: "1.5rem", padding: 5, textAlign: "center" },
            }}
            sx={textFieldStyle}
            onChange={(e) => setPassword(e.target.value)}
          />
          {isLoading ? (
            <CircularProgress sx={{ mt: 2 }} />
          ) : (
            <Button variant="outlined" type="submit" color="blanco" disabled={isLoading}>
              <Typography variant="h5">Login</Typography>
            </Button>
          )}

          {/* Display the error message */}
          {error && (
            <Typography variant="body1" color="red" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default NewVisitor;
