import { useState } from 'react';
import { Drawer, Hidden } from '@mui/material';
import DrawerHeader from './drawerHeader';
import DrawerContent from './drawerContent';

export const drawerWidth = '23%';
export const tabletDrawerWidth = '40%';

const MainDrawer = ({ mobileOpen, handleDrawerToggle }) => {
    return (
        <>
            {/* Drawer for larger screens */}
            <Hidden mdDown>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        textAlign: 'center',
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            backgroundColor: '#1E2959'
                        }
                    }}
                >
                    <DrawerHeader />
                    <DrawerContent />
                </Drawer>
            </Hidden>
            {/* Drawer for medium screens */}
            <Hidden smDown lgUp>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    sx={{
                        width: tabletDrawerWidth,
                        textAlign: 'center',
                        '& .MuiDrawer-paper': {
                            width: tabletDrawerWidth,
                            backgroundColor: '#1E2959'
                        }
                    }}
                >
                    <DrawerHeader />
                    <DrawerContent handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
            </Hidden>
            {/* Drawer for smaller screens */}
            <Hidden smUp>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            backgroundColor: '#1E2959'
                        }
                    }}
                >
                    <DrawerHeader />
                    <DrawerContent />
                </Drawer>
            </Hidden>
        </>
    );
};

export default MainDrawer;
