import React, { useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers, updateUser } from './usersSlice'
import { Button, Stack, TextField, Typography, Dialog, DialogTitle, DialogContent, Box } from '@mui/material'
import StyledDataGrid from '../../components/StyledDataGrid'
import NewUser from './NewUser'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AppButton from '../../components/AppButton'

const UsersList = () => {
    const dispatch = useDispatch()
    const users = useSelector(state => state.users.users)
    const status = useSelector(state => state.users.status)
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchUsers())
    }, [dispatch])

    const handleUpdateUser = (userId, user) => {
        const updatedUser = { ...user, active: !user.active }
        dispatch(updateUser({ userId,updatedUser }))
    }

    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    

    const rows = users.map(user => ({
        id: user._id,
        username: user.username,
        phone: user.phone,
        email: user.email,
        roles: user.roles,
        active: user.active,
        user
    }))

    const columns = [
        { field: 'username', headerName: 'Username', flex: 1 },
        { 
            field: 'phone', 
            headerName: 'Phone', 
            flex: 1,
            renderCell: (params) => (
                <TextField
                    fullWidth
                    value={params.row.phone}
                    onChange={(e) => handleUpdateUser(params.row.id, 'phone', e.target.value)}  
                />
            )
        },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'roles', headerName: 'Roles', flex: 1 },
        { 
            field: 'active', 
            headerName: 'Active', 
            flex: 1,
            renderCell: (params) => (
                params.row.active ? (
                    <CheckIcon 
                        color="success"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleUpdateUser(params.row.id, params.row.user)}
                    /> 
                    ): (
                    <ClearIcon 
                    color="error"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleUpdateUser(params.row.id, params.row.user)}
                    />
                    )
            )
        },
    ]

    if (status === 'loading') {
        return <div>Loading...</div>
    }
    if (status === 'succeeded') {

        return (
            <div>
                <Typography variant="h3">Users</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'end', mb: 3, mt: -5 }} >
                    <AppButton onClick={handleOpen} title="Add User" />
                </Box>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add New User</DialogTitle>
                <DialogContent>
                    <NewUser />
                </DialogContent>
                </Dialog>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                />
            </div>
            
        )
    }
}

export default UsersList