import React from 'react';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Tooltip } from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';


const Property = ({ property }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="View Inspections" placement='top-end' sx={{ '.MuiTooltip-tooltip': {backgroundColor: '#1e2a59'}}}>
        <IconButton onClick={handleClickOpen} color="primary">
          <PageviewIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Inspection" placement='top-start'>
        <Link to={'/inspection-form'} state={{ from: property._id }}>
          <IconButton color="secondary">
            <CreateNewFolderIcon />
          </IconButton>
        </Link>
      </Tooltip>

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          Inspections
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={10} direction='row'>
                    {property.inspections.map((inspection) => (
                        <Grid item xs={2} key={inspection._id}>
                            <Link to={`/inspections/${inspection._id}`}>{new Date(inspection.date).toLocaleDateString()}</Link>
                        </Grid>
                    ))}
                </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Property;

