import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchContacts = createAsyncThunk('contacts/fetchContacts', async () => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${API_BASE_URL}/contacts`, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const fetchContact = createAsyncThunk('contacts/fetchContact', async (contactId) => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${API_BASE_URL}/contacts/${contactId}`, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});


export const addContact = createAsyncThunk('contacts/addContact', async (newContact) => {
  const token = localStorage.getItem('token');
  const response = await axios.post(`${API_BASE_URL}/contacts`, newContact, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const updateContact = createAsyncThunk('contacts/updateContact', async (updatedContact) => {
  const token = localStorage.getItem('token');
  const response = await axios.patch(`${API_BASE_URL}/contacts`, updatedContact, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});


const contactsSlice = createSlice({
  name: 'contacts',
  initialState: { contact: null, status: 'idle', contacts: []},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContacts.pending, (state) => {
      state.status = 'loading';
    })    
    .addCase(fetchContacts.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.contacts = action.payload;
    })
    .addCase(fetchContact.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchContact.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.contact = action.payload;
    })
    .addCase(updateContact.fulfilled, (state, action) => {
      const index = state.contacts.findIndex(contact => contact._id === action.payload._id);
      state[index] = action.payload;
    })
    .addCase(addContact.fulfilled, (state, action) => {
      state.contacts.push(action.payload);
    });
  },
});

export default contactsSlice.reducer;
