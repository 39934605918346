import { useContext } from 'react';
import { Button } from '@mui/material';
import { AuthContext } from '../context/AuthContext';

const LogoutButton = () => {
  const { logout } = useContext(AuthContext);

  return <Button sx={{ color: 'white', '&:hover':{ color: 'blue'} }} onClick={logout}>LOGOUT</Button>;
};

export default LogoutButton;
