import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProperties } from './propertiesSlice';
import { Box } from '@mui/material';
import Property from './Property';
import StyledDataGrid from '../../components/StyledDataGrid';
import AppButton from '../../components/AppButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';

const PropertiesList = () => {
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.properties);
  const deviceType = useSelector((state) => state.appSettings.device);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProperties());
  }, [dispatch]);

  // DataGrid setup for tablet and desktop
  const rows = properties.map((property) => ({
    id: property._id,
    address: `${property.addressNum} ${property.addressStreet}`,
    apartmentNum: property.apartmentNum,
    lastInspection: property.lastInspectionDate ? new Date(property.lastInspectionDate).toLocaleDateString() : 'Not Inspected',
    contactName: property.propertyContact?.name,
    contactPhone: property.propertyContact?.phoneNumber,
    property,
    inspectionCurrent: property.inspectionCurrent,

  }));

  const columns = [
    { field: 'address', headerName: 'Address', flex: 1 },
    { field: 'apartmentNum', headerName: 'Apartment Number', flex: 1 },
    { field: 'lastInspection', headerName: 'Last Inspection', flex: 1 },
    { field: 'contactName', headerName: 'Contact Name', flex: 1 },
    { field: 'contactPhone', headerName: 'Contact Phone', flex: 1 },
    {
      field: 'viewInspections',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      renderCell: (params) => <Property property={params.row.property} keyProp={params.row.id} />,
    },
  ];

  // DataGrid setup for mobile
  const mobileRows = properties.map((property) => ({
    id: property._id,
    address: `${property.addressNum} ${property.addressStreet}`,
    apartmentNum: property.apartmentNum,
    property,
  }));

  const mobileColumns = [
    { field: 'address', headerName: 'Address', flex: 2 },
    { field: 'apartmentNum', headerName: 'Apartment Number', flex: 1 },
    {
      field: 'viewInspections',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      renderCell: (params) => <Property property={params.row.property} keyProp={params.row.id} />,
    },
  ];
if (deviceType === 'mobile') {
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
        <AddCircleIcon sx={{ color: '#1e2a59', fontSize: 40 }} onClick={() => navigate('/properties/new')} />
      </Box>
      <Box sx={{
        height: '86vh',
        width: '100%',
        overflow: 'scroll',
        // mb: '5rem'
      }}>
        <StyledDataGrid
          rows={mobileRows}
          columns={mobileColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>
    </div>
  );
} else {
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
        <AppButton title="Add Property" onClick={() => navigate('/properties/new')} />
      </Box>
      <Box sx={{ 
        height: '95vh', 
        width: '100%', 
        '& .inspectionIsCurrent' : { color: 'green'},
        '& .inspectionNotCurrent' : { color: 'red'}
        }}>
        
        <StyledDataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getCellClassName={(params) => {
            if (params.field === 'lastInspection') {
              if (params.row.inspectionCurrent) {
                return 'inspectionIsCurrent';
              }
              return 'inspectionNotCurrent';
            }
          }}
        />
      </Box>
    </div>
  );
};
};

export default PropertiesList;
