import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addUser } from './usersSlice';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Grid } from '@mui/material';

const NewUser = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [roles, setRoles] = useState([]);

  const handleSubmit = () => {
    const newUser = {
      username,
      password,
      email,
      phone,
      roles,
    };
    dispatch(addUser(newUser));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Add New User</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextField label="Password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextField label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="roles-label">Roles</InputLabel>
          <Select
            labelId="roles-label"
            multiple
            value={roles}
            onChange={(e) => setRoles(e.target.value)}
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Inspector">Inspector</MenuItem>
            {/* Add more roles here */}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Add User
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewUser;
