import { AppBar, Typography, Box, Toolbar, IconButton } from "@mui/material";
import { Menu as MenuIcon } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { drawerWidth } from "../drawer";
import Login from "../../auth/Login";
import { motion } from 'framer-motion';

export const appBarHeight = '5rem';

const Appbar = ({ handleDrawerToggle }) => {
    const deviceType = useSelector((state) => state.appSettings.device);
    return (
        <Box  sx={{ ml: drawerWidth, flexGrow: 1 }} >
            <AppBar
                position="fixed"
                sx={{ height: appBarHeight, backgroundColor: "#6A75A6" }}
            >
                <Toolbar sx={{ ml: { lg: drawerWidth } }}>
                    {/* Menu button for tablet */}
                    {deviceType === 'tablet' &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{ display: { lg: 'none' } }}
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    }
                    <Typography component={motion.div}  initial={{y: -250 }} animate={{y: 0}} variant='h5' sx={{ flexGrow: 1 }} transition={{ duration: 1 }}>Rimersburg Inspections</Typography>
                    <Login />
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Appbar;
