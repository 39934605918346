import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInspectionPoints, deleteInspectionPoint, selectAllInspectionPoints } from './inspectionPointsSlice';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import { DataGrid } from '@mui/x-data-grid';
import StyledDataGrid from '../../components/StyledDataGrid';
import AddPoint from './AddPoint';
import _ from 'lodash';
import AppButton from '../../components/AppButton';

const InspectionPoints = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchInspectionPoints());
  }, [dispatch]);

  const inspectionPoints = useSelector(selectAllInspectionPoints);
  const deviceType = useSelector((state) => state.appSettings.device);

  const groupedInspectionPoints = _.groupBy(inspectionPoints, 'inspectionArea');

  const handleClose = () => {
    setOpen(false);
    dispatch(fetchInspectionPoints());
  };

  const handleDelete = (id) => {
    dispatch(deleteInspectionPoint(id));
    dispatch(fetchInspectionPoints());
  }

  return (
    <div>
      <h1>Inspection Points</h1>
      <Dialog open={open} onClose={handleClose} maxWidth='xl'>
        <AddPoint handleClose={handleClose} />
      </Dialog>
      <Box sx={{ position: 'fixed', top: '100px', right: '30px', zIndex: 13 }}>
        {deviceType !== 'mobile' && <AppButton onClick={() => setOpen(true)} title="Add Inspection Point" />}
        {deviceType === 'mobile' && <AddCircleIcon fontSize='large' onClick={() => setOpen(true)}/>}
      </Box>
      {Object.keys(groupedInspectionPoints).map((area) => (
        <Box key={area} sx={{ marginBottom: '20px' }}>
          <h2>{area}</h2>
          <div style={{ height: 400, width: '100%' }}>
            <StyledDataGrid
              rows={groupedInspectionPoints[area].map((point, i) => ({ id: i, ...point }))}
              columns={[
                { field: 'inspectionPoint', headerName: 'Inspection Point', flex: 1 },
                {
                  field: 'delete',
                  headerName: 'Actions',
                  sortable: false,
                  width: 100,
                  renderCell: (params) => (
                    <Tooltip title='Delete Inspection Point'>
                      <IconButton
                        onClick={() => handleDelete(params.row._id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }
              ]}
              pageSize={10}
              pagination
              autoPageSize={false}
              rowsPerPageOptions={[10]}
            />
          </div>
        </Box>
      ))}
    </div>
  );
};

export default InspectionPoints;
