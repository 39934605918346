import { configureStore } from '@reduxjs/toolkit';
// import { apiSlice } from '../app/api/apiSlice';
import propertiesReducer from '../features/properties/propertiesSlice';
import contactsReducer from '../features/contacts/contactsSlice';
import inspectionsReducer from '../features/inspections/inspectionsSlice';
import inspectionPointsReducer from '../features/inspectionPoints/inspectionPointsSlice';
import usersReducer from '../features/users/usersSlice';
import notesReducer from '../features/notes/notesSlice';
import appSettingsReducer from './appSettingsSlice';

export const store = configureStore({
    reducer: {
        properties: propertiesReducer,
        contacts: contactsReducer,
        inspections: inspectionsReducer,
        inspectionPoints: inspectionPointsReducer,
        notes: notesReducer,
        users: usersReducer,
        appSettings: appSettingsReducer,
    },
    // disable for deployment
    devTools: true
});