import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Entity adapter
const inspectionPointsAdapter = createEntityAdapter({
    selectId: (inspectionPoint) => inspectionPoint._id,
  });

// Initial state
const initialState = inspectionPointsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

// Fetch data
export const fetchInspectionPoints = createAsyncThunk('inspectionPoints/fetchInspectionPoints', async () => {
  const response = await axios.get(`${API_BASE_URL}/inspection-points`);
  return response.data;
});

// Add inspection point
export const postInspectionPoint = createAsyncThunk('inspectionPoints/postInspectionPoint', async (newInspectionPoint) => {
  const response = await axios.post(`${API_BASE_URL}/inspection-points`, newInspectionPoint);
  return response.data;
});

// Delete inspection point
export const deleteInspectionPoint = createAsyncThunk('inspectionPoints/deleteInspectionPoint', async (id) => {
  await axios.delete(`${API_BASE_URL}/inspection-points`, { data: { id }});
  return id;
});

// Slice
const inspectionPointsSlice = createSlice({
  name: 'inspectionPoints',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInspectionPoints.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInspectionPoints.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched inspection points to the array
        inspectionPointsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchInspectionPoints.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(postInspectionPoint.fulfilled, (state, action) => {
        inspectionPointsAdapter.addOne(state, action.payload);
      })
      .addCase(deleteInspectionPoint.fulfilled, (state, action) => {
        inspectionPointsAdapter.removeOne(state, action.payload);
      });
  },
});

export default inspectionPointsSlice.reducer;

// Export the customized selectors
export const {
  selectAll: selectAllInspectionPoints,
  selectById: selectInspectionPointById,
  // selectIds: selectInspectionPointIds, // And so on...
} = inspectionPointsAdapter.getSelectors((state) => state.inspectionPoints);