import { Paper, Typography } from '@mui/material'

const Agenda = () => {
  return (
        <Paper sx={{ height: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Typography variant="h6">
                Coming Soon
            </Typography>
        </Paper>
  )
}

export default Agenda