import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNotes } from '../notes/notesSlice';
import CreateNote from '../notes/CreateNote';
import PropertyStatus from './PropertyStatus';
import { Grid, CircularProgress, Box } from '@mui/material';
import NoteList from '../notes/NoteList';
import Schedule from '../schedule';

const Dashboard = () => {
  const dispatch = useDispatch();
  const notesStatus = useSelector(state => state.notes.status);
  const deviceType = useSelector((state) => state.appSettings.device);

  useEffect(() => {
    if (notesStatus === 'idle') {
      dispatch(fetchNotes());
    }
  }, [notesStatus, dispatch]);

  if (notesStatus === 'loading') {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </div>
    );
  }

  if (notesStatus === 'failed') {
    return <div>Error loading notes</div>;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} >
          <PropertyStatus />
          <Schedule />
        </Grid>
        <Grid item xs={12} sm={6} >
          <Box sx={{ p: 2, backgroundColor: '#1e2a59', borderRadius: 5 }}>
            <CreateNote />
            <NoteList />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
