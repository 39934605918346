import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const menuItems = [
    {
        name: 'Dashboard',
        path: '/',
        icon: <DashboardIcon fontSize='large'/>
    },
    {
        name: 'Property List',
        path: '/properties',
        icon: <HomeIcon fontSize='large'/>
    },
    {
        name: 'Contact List',
        path: '/contacts',
        icon: <PeopleIcon fontSize='large'/>
    },
    {
        name: 'More',
        icon: <MoreHorizIcon fontSize='large'/>,
    }
    // {
    //     name: 'Inspection Points',
    //     path: '/points-of-inspection',
    //     icon: <AssignmentTurnedInIcon />
    // },
    // {
    //     name: 'Users',
    //     path: '/users',
    //     icon: <PeopleIcon />
    // },
]

export default menuItems;
