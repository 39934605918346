import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchNotes = createAsyncThunk('notes/fetchNotes', async () => {
  const response = await axios.get(`${API_BASE_URL}/notes`);
  return response.data;
});

export const postNote = createAsyncThunk('notes/postNote', async ({content, author, isPublic, tags}) => {
    const response = await axios.post(`${API_BASE_URL}/notes`, { content, author, isPublic, tags });
    return response.data;
});

export const postReply = createAsyncThunk('notes/postReply', async ({ noteId, reply }) => {
  const response = await axios.post(`${API_BASE_URL}/notes/${noteId}/replies`, reply);
  return response.data;
});

export const deleteNote = createAsyncThunk('notes/deleteNote', async ({ noteId }) => {
  await axios.delete(`${API_BASE_URL}/notes/${noteId}`);
  return noteId; // Just return the noteId directly
});

export const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    notes: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchNotes.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.notes = action.payload;
      })
      .addCase(postNote.fulfilled, (state, action) => {
        // Clone the response data
        const newNote = { ...action.payload };
        newNote.author = { username: action.meta.arg.author };
        state.notes.push(newNote);
      })
      .addCase(postReply.fulfilled, (state, action) => {
        const { noteId, reply } = action.payload;
        const note = state.notes.find(note => note._id === noteId); // Use ._id if that's what your backend uses
        if (note) {
          note.replies.push(reply);
        }
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        const noteId = action.payload;
        state.notes = state.notes.filter(note => note._id !== noteId); // Use ._id if that's what your backend uses
      });
  }
});

export default notesSlice.reducer;
