import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const token = localStorage.getItem('token');
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await axios.get(`${API_BASE_URL}/users`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
    }
);

export const fetchUser = createAsyncThunk('users/fetchUser', async (userId) => {
    const response = await axios.get(`${API_BASE_URL}/users/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
    }
);

export const addUser = createAsyncThunk('users/addUser', async (newUser) => {
    const response = await axios.post(`${API_BASE_URL}/users`, newUser, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
    }
);

// Update user
export const updateUser = createAsyncThunk('users/updateUser', async (updatedUser) => {
    const response = await axios.patch(`${API_BASE_URL}/users`, updatedUser, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
    }
);

// Update user active
export const updateUserActiveStatus = createAsyncThunk('users/updateUserActiveStatus', async ({ userId, isActive }, thunkAPI) => {
    const response = await axios.patch(`${API_BASE_URL}/users/${userId}/`, { active: isActive },
    {
        headers: {
            Authorization: `Bearer ${token}`,
            },
            }
            );
            return { userId, ...response.data };
            }
            );


const usersSlice = createSlice({
    name: 'users',
    initialState: { user: null, status: 'idle', users: []},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.pending, (state) => {
            state.status = 'loading';
        })    
        .addCase(fetchUsers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.users = action.payload;
        })
        .addCase(fetchUser.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchUser.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.user = action.payload;
        })
        .addCase(addUser.fulfilled, (state, action) => {
            state.users.push(action.payload);
        })
        .addCase(updateUser.fulfilled, (state, action) => {
            state.users = state.users.map(user =>
                user._id === action.payload.user._id ? action.payload.user : user
            );
        })
    }
});

export default usersSlice.reducer;