import { Box, Stack, Typography } from '@mui/material'
import menuItems from './menu-items'
import { useNavigate } from 'react-router-dom'

export const footerHeight = '5rem';

const footerContainer = {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-evenly',
    bottom: 0,
    width: '100%',
    height: footerHeight,
    backgroundColor: "#6A75A6",
    mt: 5,
    mb: 0,
    zIndex: 90000
}

const AppFooter = () => {
    const navigate = useNavigate();
    const handleSelection = (item) => {
        navigate(item.path);
    }
    return (
        <Box sx={footerContainer}>
            <Stack direction="row" spacing={3} sx={{ p: 2 }}>
                {menuItems.map((item) => (
                    <Box key={item.name} onClick={handleSelection.bind(null, item)}
                        sx={{ display: 'flex', color: "white", fontFamily: "Assistant", alignItems: 'center', flexDirection: 'column' }}>
                        {item.icon}
                        <Typography color='white'>
                            {item.name}
                        </Typography>
                    </Box>
                ))}
            </Stack>
        </Box>
    )
}

export default AppFooter