import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
    spacing: 8,
    palette: {
        mode: 'light',
        primary: {
            main: '#1976d2',
        },
        info: {
            main: '#FADD60',
        },
        blanco: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#6A75A6',
        },
        complement: {
            main: '#232F66',
        }
    },
    typography: {
        fontFamily: [
            'Assistant',
        ],
        // body1: {
        //     fontWeight: 700,
        //     color: 'white'
        // },
    }
});

export default responsiveFontSizes(theme);