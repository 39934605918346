import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchInspection } from './inspectionsSlice'
import { Box, Grid, Paper, Stack, Typography } from '@mui/material'

const Inspection = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const inspection = useSelector(state => state.inspections.inspection)
    const status = useSelector(state => state.inspections.status)
    
    // console.log(inspection.inspector)
    useEffect(() => {
        dispatch(fetchInspection(id))
    }, [dispatch, id])

    if (status === 'loading') {
        return <div>Loading...</div>
    }

    if (status === 'succeded' && !inspection) {
        return <div>Inspection not found</div>
    }
    if (status === 'succeeded' && inspection) {
        const inspectionPoints = inspection.pointsOfInspection
        const dateTitle = new Date(inspection.date).toLocaleDateString()
        console.log(inspection.inspector)

        // Groupoing the points by inspection area
        const inspectionPointsByArea = inspectionPoints.reduce((groups, point) => {
            const key = point.inspectionArea;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(point);
            return groups;
        }, {});
        return (
            <Paper elevation={5} sx={{ m:2, p: 5 }}>
                <Typography variant='h3' align='center' sx={{ mb: 0 }}>{inspection.property.addressNum} {inspection.property.addressStreet}</Typography>
                <Typography variant='h4' align='center' sx={{ mb: 4 }}> {dateTitle} </Typography>
                {Object.entries(inspectionPointsByArea).map(([area, points], index) => (
                        <Box key={index} >
                            <Typography variant='h3'>{area}</Typography>
                            <Box sx={{ border: '1px solid black'}}>
                            {points.map(point => (
                                    <Box key={point._id} sx={{ ml: 5, border: '1px solid black' }}>
                                        <Grid container spacing={2} fullWidth>
                                            <Grid item xs={4} >
                                                <Typography variant='h5'>{point.point}: {point.score}</Typography>
                                            </Grid>
                                            <Grid item xs={8} >
                                                <Typography variant='h5'>{point.notes}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                            ))}
                            </Box>
                        </Box>
                    ))}
                {inspection.signature && <img src={inspection.signature} alt='Property Contact Signature'/>}
                <Typography variant='h5' align='center'>Inspected by: {inspection.inspector.username}</Typography>

            </Paper>
        )
    }
}

export default Inspection