import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchContact, updateContact } from '../contacts/contactsSlice';
import { TextField, Button, CircularProgress, Box, Typography, Stack, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';


const ContactInfo = () => {
  const { id } = useParams(); // assumes your route is /contacts/:id
  const dispatch = useDispatch();
  const contact = useSelector(state => state.contacts.contact);
  const status = useSelector(state => state.contacts.status);
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);

  console.log(contact)

  //Local state for editing the contact
  const [editContact, setEditContact] = useState({
  });


  // Fetch the contact when the component mounts and when id changes
  useEffect(() => {
    dispatch(fetchContact(id));
  }, [dispatch, id]);

//   Update the local state when the contact changes
  useEffect(() => {
    if (contact) {
        setEditContact({
            ...contact,
            id: id,
            name: contact.name,
            role: contact.type,
            phoneNumber: contact.phoneNumber,
            address: contact.address,
            email: contact.email
        });
    }   
    // console.log(editContact);
  }, [contact]);
  console.log(contact);

    // Toggle edit mode
    const toggleEditMode = () => {
        setEditMode(!editMode);
    };
//   Handle input changes
  const handleChange = (event) => {
    setEditContact({ ...editContact, [event.target.name]: event.target.value });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateContact(editContact));
    navigate('/contacts');
  };
    if (status === 'loading') {
        return <CircularProgress />;
    }


    if(!contact) {
        return <Typography>Could not find contact</Typography>;
    }
    if (!editMode) {
        return (
            <Box sx={{ border: '1px solid black', mx: [5, 5, 20], p: 2 }}>
                <Grid container >
                    <Grid item xs={12} sx={{ border: '1px solid black', backgroundColor: '#1E2959'}}>
                        <Stack direction='row' sx={{ justifyContent: 'space-between'}}>
                            <Typography variant='h2' sx={{ fontStyle: 'italic', color: 'white'}} >
                                {contact.name}
                            </Typography>
                            <Button onClick={toggleEditMode}>
                                <EditIcon fontSize='large' color='blanco'/>
                            </Button>
                        </Stack>
                    </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2}}>
                    <Grid item xs={4}>
                        <Typography variant='h5'>Contact Type:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='h5'>{contact.role}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='h5'>Contact Address:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='h5'>
                            {contact.address ? contact.address : "No address provided"}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='h5'>Contact Phone Number:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='h5'>
                            {contact.phoneNumber ? contact.phoneNumber : "No phone number provided"}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='h5'>Contact Email:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='h5'>
                            {contact.email ? contact.email : "No email provided"}
                        </Typography>
                    </Grid>
                </Grid>
                {
                    contact.properties && contact.properties.length > 0 ? (
                        <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Associated Properties:</Typography>
                        </Grid>
                        {contact.properties.map((property, index) => (
                            <Grid item xs={12} key={index}>
                            <Typography variant="body1">{property.addressNum + ' ' +property.addressStreet +' ' +'Apt.' + property.apartmentNum}</Typography>
                            </Grid>
                        ))}
                        </Grid>
                    ) : (
                        <Typography variant="body1">No associated properties</Typography>
                    )
                    }
            </Box>
        );
    }
    if (editMode) {
        return (
            <Box sx={{ border: '1px solid black', mx: 20 }}>
            <form onSubmit={handleSubmit}>
            <Grid container >
                    <Grid item xs={12} sx={{ border: '1px solid black', backgroundColor: '#1E2959'}}>
                        <Stack direction='row' sx={{ justifyContent: 'space-between'}}>
                            <Typography variant='h2' sx={{ fontStyle: 'italic', color: 'white'}} >
                                {contact.name}
                            </Typography>
                            <Button onClick={toggleEditMode}>
                                <EditIcon fontSize='large' color='blanco'/>
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid container sx={{ mt: 2}}>
                    <Grid item xs={4}>
                        <Typography variant='h5'>
                            Contact Name:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <TextField name="name" value={editContact.name} onChange={handleChange} label="Name" margin='none' size='small' fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='h5'>
                            Contact Type:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl fullWidth margin='none' hiddenLabel size='small'>
                            <InputLabel>Contact Type</InputLabel>
                            <Select
                                name='role'
                                value={editContact.role}
                                onChange={handleChange}
                                defaultValue={editContact.role}
                                >
                                <MenuItem value={"Manager"}>Manager</MenuItem>
                                <MenuItem value={"Owner"}>Owner</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='h5'>
                            Contact Address:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <TextField name="address" value={editContact.address} fullWidth onChange={handleChange} label="Address" margin='none' size='small' />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='h5'>
                            Contact Phone Number:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <TextField name="phoneNumber" value={editContact.phoneNumber} onChange={handleChange} label="Phone Number" margin='none' size='small' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='h5'>
                            Contact Email:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField name="email" value={editContact.email} onChange={handleChange} label="Email" margin='none' size='small' fullWidth />
                    </Grid>
                    </Grid>
                </Grid>
                <Button type="submit" variant='contained' color='complement' sx={{ color: 'white', my: 1 }} fullWidth>Update</Button>
            </form>
            
        </Box>
        );
    }
    
};

export default ContactInfo;