import React from 'react'
// Project imports
import menuItems from './menu-items'

// Material UI imports
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// React Router Dom imports
import { useNavigate } from 'react-router-dom';

const DrawerContent = ({ handleDrawerToggle }) => {
  console.log(handleDrawerToggle)
    const navigate = useNavigate();
    const handleSelection = (item) => {
        // handleDrawerToggle();
        navigate(item.path);
    }

  return (
    <List sx={{ width: "100%", bgcolor: "#1E2959" }}>
      {menuItems.map((item) => (
        <ListItemButton
          key={item.name}
          autoFocus
          sx={{ pl: 5 }}
          onClick={handleSelection.bind(null, item)}
        >
          <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
          <ListItemText
            disableTypography
            primary={item.name}
            sx={{
              color: "white",
              fontSize: "32px",
              fontFamily: "Assistant",
              "&&:hover": { color: "#00FFFF" },
            }}
          />
        </ListItemButton>
      ))}
    </List>
  );
};

export default DrawerContent