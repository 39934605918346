import { useState, useContext, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { isTokenExpired } from '../context/AuthContext';
import Logout from './Logout';
import jwtDecode from 'jwt-decode';

const Login = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, setCurrentUser } = useContext(AuthContext);

  const { currentUser, logout } = useContext(AuthContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && isTokenExpired(token)) {
      logout();
    }
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const token = await login(username, password);

    // Store the token in local storage
    localStorage.setItem('token', token);

    // Decode the token to get the user object
    const decodedToken = jwtDecode(token);
    setCurrentUser(decodedToken);
    // console.log(decodedToken)
    handleClose();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  if (!currentUser) {
    return (
      <div>
        <Button sx={{ color: 'white' }} onClick={handleOpen}>LOGIN</Button>
        <Dialog open={isOpen} >
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <TextField
                margin="dense"
                label="Username"
                type="username"
                fullWidth
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Login</Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  } else {
  return (
    <div>
      <Logout />
      </div>
      )
  };
}
export default Login;