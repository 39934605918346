import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box } from '@mui/material';

const RadioButtons = ({ index, handleBlur, point, inspectionArea, radioValue }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column',width: '30%'}}>
            <FormLabel>{point.inspectionArea}: {point.inspectionPoint}</FormLabel>
            <FormControl component="fieldset">
            <RadioGroup 
              row 
              onChange={(e) => handleBlur(index, 'score', e.target.value, point.inspectionPoint, inspectionArea)}
              value={radioValue}
              >
                    <FormControlLabel value="Pass" control={<Radio />} label="Pass" />
                    <FormControlLabel value="Fail" control={<Radio />} label="Fail" />
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default RadioButtons;