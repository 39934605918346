import { DataGrid } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)({
    '& .inspection-current': {
      // color: 'rgba(13, 255, 49)',
      // fontSize: '1.2rem',
      // fontWeight: 'bold',
      
    },
    '& .inspection-not-current': {
      color: 'rgb(251, 12, 0)',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#6A75A6',
      color: 'white',
      fontSize: '1.2rem',
    }
  });

export default StyledDataGrid