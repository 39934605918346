import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import { deleteNote } from './notesSlice';
// import { postReply } from './notesSlice';

const Note = ( note ) => {
  // const [reply, setReply] = useState('');

  // const user = useSelector(state => state.user.user);
  const notes = useSelector(state => state.notes.notes);
  const dispatch = useDispatch();
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDelete = (id) => {
    dispatch(deleteNote({
      noteId: id
    }));
  };

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   if (reply) {
  //     dispatch(postReply({
  //       noteId: note.id,
  //       author: user.username,
  //       content: reply,
  //     }));
  //     setReply('');
  //   }
  // };

  return (
    <div>
      <Box sx={{ border: '1px solid black', borderRadius: 3, mt: 2, p: 2, backgroundColor: '#f5f5f5'}}>
        <Typography variant="h5">{note.note.author.username}</Typography>
        <Typography sx={{ fontStyle: 'italic'}}>{formatDate(note.note.postDate)}</Typography>
        <Typography>{note.note.content}</Typography>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={() => handleDelete(note.note._id)}>Mark Completed</Button>
        </Box>
      </Box>
      {/* {note.replies.map((reply, i) => (
        <div key={i}>
          <h3>{reply.author}</h3>
          <p>{reply.content}</p>
        </div> */}
      {/* ))} */}
      {/* <form>
         onSubmit={handleSubmit}>
        <textarea
          value={reply}
          onChange={e => setReply(e.target.value)}
          placeholder="Write a reply..."
        />
        <button type="submit">Reply</button>
      </form> */}
    </div>
  );
}

export default Note;
