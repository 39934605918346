import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProperty } from './propertiesSlice';
import { addContact, fetchContacts } from '../contacts/contactsSlice';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Stack, Paper } from '@mui/material';

const AddProperty = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [newProperty, setNewProperty] = useState({
        addressNum: "",
        addressStreet: "",
        apartmentNum: "",
        propertyContact: "",
    });

    const [newContact, setNewContact] = useState({
        name: "",
        type: "", // owner or manager
        phoneNumber: "",
        address: "",
        email: "",
    });

    useEffect(() => {
        dispatch(fetchContacts());
    }, [dispatch]);

    const contacts = useSelector(state => state.contacts.contacts);

    const handleChangeProperty = (event) => {
        setNewProperty({ ...newProperty, [event.target.name]: event.target.value });
    };

    const handleChangeContact = (event) => {
        setNewContact({ ...newContact, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

    // Check if contact already exists
        const contactExists = contacts.find(contact => contact.name === newContact.name);
        let contactId = "";

        if (contactExists) {
      // Use the existing contact's id
            contactId = contactExists._id;
        } else {
      // Add new contact and get id
            const addedContact = await dispatch(addContact(newContact)).unwrap();
            contactId = addedContact.contact._id;
        }

    // Add property with contactId
        const addedProperty = {
            ...newProperty,
            propertyContact: contactId,
        };
        dispatch(addProperty(addedProperty));
        navigate('/properties');
        
    };
    return (
        <Paper elevation={3} sx={{ p: 20, m: 10 }}>
            <form onSubmit={handleSubmit}>
                <h1>Add Property</h1>
                <Stack spacing={2} direction="column">
                {/* New Property Fields */}
                    <Stack direction="row" spacing={2}>
                        <TextField name="addressNum" value={newProperty.addressNum} autoFocus onChange={handleChangeProperty} label="Street Number" />
                        <TextField name="addressStreet" value={newProperty.addressStreet} onChange={handleChangeProperty} label="Street" />
                        <TextField name="apartmentNum" value={newProperty.apartmentNum} onChange={handleChangeProperty} label="Apartment Number" />
                        </Stack>
                
                {/* New Contact Fields */}
                    <TextField name="name" value={newContact.name} onChange={handleChangeContact} label="Contact Name" />
                    <FormControl>
                        <InputLabel>Contact Type</InputLabel>
                        <Select name="type" value={newContact.type} onChange={handleChangeContact}>
                            <MenuItem value={"manager"}>Manager</MenuItem>
                            <MenuItem value={"owner"}>Owner</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField name="phoneNumber" value={newContact.phoneNumber} onChange={handleChangeContact} label="Phone Number" />
                    <TextField name="address" value={newContact.address} onChange={handleChangeContact} label="Address" />
                    <TextField name="email" value={newContact.email} onChange={handleChangeContact} label="Email" />

                    <Button type="submit">Add Property</Button>
                </Stack>   
            </form>
        </Paper>
    );
};

export default AddProperty;
